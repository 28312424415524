import { Injectable } from '@angular/core';

declare var $: any;

@Injectable()
export class KontaktService {
  constructor() { }



  postMsg(d) {
    return $.ajax({
      url: 'kontaktforma.php',
      // url: 'http://www.zaveseistolnjaci.rs/kontaktforma.php',
      type: 'post',
      data:d,
      // success: function(data) {
      //   console.log(data);
      //   // alert('aaaaa');
      // }
    }).done(function(res){
      console.log(res);
    })
      .fail(function(er){
        console.log('there was an error php ' + er)
      })
  }


}

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {HeaderComponent} from './header/header.component';
import {RouterModule, Routes} from "@angular/router";
import {FooterComponent} from './footer/footer.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HomeComponent} from './home/home.component';
import {KarooselComponent} from './home/karoosel/karoosel.component';
import {AboutComponent} from './home/about/about.component';
import {MainComponent} from './home/main/main.component';
import {KontaktformaComponent} from './home/kontaktforma/kontaktforma.component';
import {GalerijaComponent} from './galerija/galerija.component';
import {KaruselComponent} from './galerija/karusel/karusel.component';
import {HttpClientModule } from '@angular/common/http';
import {KontaktService} from "./kontakt.service";
import {FormsModule} from "@angular/forms";


declare var $: any;

const appRoutes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'galery/:product', component: GalerijaComponent},
  // {path: '**', redirectTo: '', pathMatch: 'full'},
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    KarooselComponent,
    AboutComponent,
    MainComponent,
    KontaktformaComponent,
    GalerijaComponent,
    KaruselComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, {useHash: true}),
    HttpClientModule
  ],
  providers: [KontaktService],
  bootstrap: [AppComponent]
})
export class AppModule {
}

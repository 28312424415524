import { Component, OnInit, Input } from '@angular/core';

import {SLIDER_IMAGES} from "../../../constants/header.js";

declare var $: any;
@Component({
  selector: 'app-karoosel',
  templateUrl: './karoosel.component.html',
  styleUrls: ['./karoosel.component.scss'],
})
export class KarooselComponent implements OnInit {

  @Input() currentState

  showOverlayWithText = true;

  pictures = SLIDER_IMAGES;



  constructor() {

  }

  ngOnInit() {
    $(".carousel").swipe({

      swipe: function (event, direction, distance, duration, fingerCount, fingerData) {

        if (direction == 'left') $(this).carousel('next');
        if (direction == 'right') $(this).carousel('prev');

      },
      allowPageScroll: "vertical"
    });

  }



}

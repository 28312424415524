import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  showCarusel = false;

  products = ['Zavese', 'Stolnjaci'];

  makeProdImgSrc(item) {
    return item == 'Zavese' ? 'assets/images/products/zavese.png' : 'assets/images/products/sto.png';
  }

  showProduct(item) {
    this.router.navigate(['/galery', item.toLowerCase()]);
  }

  toggleCarusel = function() {
    this.showCarusel = !this.showCarusel;
    console.log(1);
  };

  constructor( private router: Router) {

  }

  ngOnInit() {
    window.scroll(0, 0);
  }
}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {MyEventsService} from "../my-events";

import {galeryImages} from "../../constants/header.js";

@Component({
  selector: 'app-galerija',
  templateUrl: './galerija.component.html',
  styleUrls: ['./galerija.component.scss']
})
export class GalerijaComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private router: Router,
              private myEventsService: MyEventsService) {

    this.myEventsService.eventCloseGaleri.subscribe(
      (action: string) => {this.showkarusel = action=='close'? false : true;}
    )
  }


  activePicture = 0;

  stolnjacitxt = galeryImages.stolnjacitxt;
  zavesetxt = galeryImages.zavesetxt;

  stolnjaci = galeryImages.stolnjaci;
  // stolnjaci = [
  //   'src/assets/images/products/stolnjaci/IMG_0824.JPG',
  //   'src/assets/images/products/stolnjaci/IMG_0918.JPG',
  //   'src/assets/images/products/stolnjaci/IMG_0922.JPG',
  //   'src/assets/images/products/stolnjaci/IMG_0923.JPG',
  //   'src/assets/images/products/stolnjaci/IMG_6860.JPG',
  //   'src/assets/images/products/stolnjaci/IMG_6863.JPG',
  //   'src/assets/images/products/stolnjaci/IMG_6868.JPG',
  // ];

  zavese = galeryImages.zavese;
  // zavese = [
  //   'src/assets/images/products/zavese/1.JPG',
  //   'src/assets/images/products/zavese/2.JPG',
  //   'src/assets/images/products/zavese/3.JPG',
  //   'src/assets/images/products/zavese/4.JPG',
  //   'src/assets/images/products/zavese/5.JPG',
  //   'src/assets/images/products/zavese/6.JPG',
  //   'src/assets/images/products/zavese/7.JPG',
  //   'src/assets/images/products/zavese/8.JPG',
  //   'src/assets/images/products/zavese/9.JPG',
  //   'src/assets/images/products/zavese/10.JPG',
  //   'src/assets/images/products/zavese/11.JPG',
  //   'src/assets/images/products/zavese/12.JPG',
  //   'src/assets/images/products/zavese/13.JPG',
  //   'src/assets/images/products/zavese/14.JPG',
  //   'src/assets/images/products/zavese/15.JPG',
  //   'src/assets/images/products/zavese/16.JPG',
  //   'src/assets/images/products/zavese/18.JPG',
  //   'src/assets/images/products/zavese/19.JPG',
  //   'src/assets/images/products/zavese/20.JPG',
  //   'src/assets/images/products/zavese/21.JPG',
  //   'src/assets/images/products/zavese/22.JPG',
  //   'src/assets/images/products/zavese/23.JPG',
  //   'src/assets/images/products/zavese/24.JPG',
  //   'src/assets/images/products/zavese/25.JPG',
  //   'src/assets/images/products/zavese/26.JPG',
  //   'src/assets/images/products/zavese/27.JPG',
  //   'src/assets/images/products/zavese/28.JPG',
  //   'src/assets/images/products/zavese/29.JPG',
  //   'src/assets/images/products/zavese/30.JPG',
  // ];

  pictures = [];
  text = '';

  showkarusel = false;
  aktivan = '';

  productClicked(el) {
    this.router.navigate(['/galery', el.toLowerCase()]);
  }

  picturClicked(index) {
    this.activePicture = index;
    this.showkarusel = true;
  };

  ngOnInit() {

    window.scroll(0, 0);
    this.pictures = this[this.route.snapshot.params['product']];
    this.text = this[this.route.snapshot.params['product']  + 'txt'];
    this.aktivan = this.route.snapshot.params['product'];

    this.route.params.subscribe(
      (params: Params) => {
        this.pictures = this[params['product']];
        this.text = this[params['product'] + 'txt'];
        this.aktivan = this.route.snapshot.params['product'];
      }
    )
  }

}

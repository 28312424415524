import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MyEventsService} from "../../my-events";
import {Router} from "@angular/router";

declare var $: any;

@Component({
  selector: 'app-karusel',
  templateUrl: './karusel.component.html',
  styleUrls: ['./karusel.component.scss']
})
export class KaruselComponent implements OnInit, OnDestroy {

  @Input () aktivna;

  constructor( private myEventsService: MyEventsService,
               private router: Router) { }

  @Input () pictures;

  exitGaleri () {
    this.myEventsService.eventCloseGaleri.emit('close');
  }

  ngOnInit() {
    $(".carousel").swipe({

      swipe: function (event, direction, distance, duration, fingerCount, fingerData) {

        if (direction == 'left') $(this).carousel('next');
        if (direction == 'right') $(this).carousel('prev');

      },
      allowPageScroll: "vertical"
    });
    $('body').css({overflow:'hidden'});
  }

  ngOnDestroy() {
    $('body').css({overflow:''});
  }

}

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { CONTACT_FORM } from "../../../constants/header.js";
import {KontaktService} from "../../kontakt.service";
import {NgForm} from "@angular/forms";


declare var $: any;

@Component({
  selector: 'app-kontaktforma',
  templateUrl: './kontaktforma.component.html',
  styleUrls: ['./kontaktforma.component.scss']
})


export class KontaktformaComponent implements OnInit {

  @ViewChild('f') contactForm: NgForm;

  telefon =  CONTACT_FORM.TELEFON;
  mail = CONTACT_FORM.MAIL;

  dataZaSlanje = '';
  msgSent = false;

  constructor(private kontactService: KontaktService) { }

  onSubmit1 (form: NgForm) {
    console.log('Sumbmited');
    console.log(form);
    this.contactForm.reset();
    $.post('./kontaktforma.php', {a:'aaa', b:'bbb'}, function (data) {
      alert(data);
      this.msgSent = true;
    });
  }



  onSubmit() {
    console.log('send mail');
    this.dataZaSlanje = $('#cForm').serialize();
    this.kontactService.postMsg(this.dataZaSlanje).then(console.log('kraj'));


    // $.ajax({
    //   url: 'kontaktforma.php',
    //   type: 'POST',
    //   dataType: 'json',
    //   data: this.dataZaSlanje,
    //   success: function(response){
    //     debugger;
    //     console.log('zavrsio php');
    //     console.log(response);
    //
    //   }//end success
    //
    // }); //end ajax
  }


  ngOnInit() {


    // $('#cForm').on('submit', function (e) {
    //   e.preventDefault();
    //   $.ajax({
    //     url: 'kontaktforma.php',
    //     type: 'POST',
    //     dataType: 'json',
    //     data: $('#cForm').serialize(),
    //     success: function (response) {
    //       console.log('zavrsio php');
    //       console.log(response);
    //
    //     }//end success
    //
    //   }); //end ajax
    // });


      $( '.js-input' ).keyup(function() {
      if( $(this).val() ) {
        $(this).addClass('not-empty');
      } else {
        $(this).removeClass('not-empty');
      }
    });
  }
}

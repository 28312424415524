import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})



export class MyEventsService {

eventCloseGaleri = new EventEmitter<string>();
event2 = new EventEmitter<string>();
event3 = new EventEmitter<string[]>();
event4 = new EventEmitter<string>();
dajGaleriju = new EventEmitter<number>();
staDaPokazem = new EventEmitter<number>();
eventWhatToShow = new EventEmitter<string>();
kojaGalerija = new EventEmitter<string>();
goToPage = new EventEmitter<string>();

navClicked = new EventEmitter<string>()

// setPictures = new EventEmitter<string>()




stolnjaci = [
  'assets/images/products/stolnjaci/IMG_0824.JPG',
  'assets/images/products/stolnjaci/IMG_0918.JPG',
  'assets/images/products/stolnjaci/IMG_0922.JPG',
  'assets/images/products/stolnjaci/IMG_0923.JPG',
  'assets/images/products/stolnjaci/IMG_6860.JPG',
  'assets/images/products/stolnjaci/IMG_6863.JPG',
  'assets/images/products/stolnjaci/IMG_6864.JPG',
  'assets/images/products/stolnjaci/IMG_6868.JPG',
]

zavese = [
  'assets/images/products/zavese/1.JPG',
  'assets/images/products/zavese/2.JPG',
  'assets/images/products/zavese/3.JPG',
  'assets/images/products/zavese/4.JPG',
  'assets/images/products/zavese/5.JPG',
  'assets/images/products/zavese/6.JPG',
  'assets/images/products/zavese/7.JPG',
  'assets/images/products/zavese/8.JPG',
  'assets/images/products/zavese/9.JPG',
  'assets/images/products/zavese/10.JPG',
  'assets/images/products/zavese/11.JPG',
  'assets/images/products/zavese/12.JPG',
  'assets/images/products/zavese/13.JPG',
  'assets/images/products/zavese/14.JPG',
  'assets/images/products/zavese/15.JPG',
  'assets/images/products/zavese/16.JPG',
  'assets/images/products/zavese/17.JPG',
  'assets/images/products/zavese/18.JPG',
  'assets/images/products/zavese/19.JPG',
  'assets/images/products/zavese/20.JPG',
  'assets/images/products/zavese/21.JPG',
  'assets/images/products/zavese/22.JPG',
  'assets/images/products/zavese/23.JPG',
  'assets/images/products/zavese/24.JPG',
  'assets/images/products/zavese/25.JPG',
  'assets/images/products/zavese/26.JPG',
  'assets/images/products/zavese/27.JPG',
  'assets/images/products/zavese/28.JPG',
  'assets/images/products/zavese/29.JPG',
  'assets/images/products/zavese/30.JPG',
]

pictures = []

aktivnaSlika  = 0;

setPictures(ss:string){
  debugger;
  this.pictures = this[ss.toLowerCase()];
}

getPictures() {
  return this.pictures.slice();
}

setPic(ss:number){

  this.aktivnaSlika = ss;
}

getPic() {
  return this.aktivnaSlika;
}

constructor() { }

}

import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

import {NAV_MENU_ITEMS} from "../../constants/header.js";

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  firstPart = NAV_MENU_ITEMS.slice(0, NAV_MENU_ITEMS.length / 2);
  secondPart = NAV_MENU_ITEMS.slice(NAV_MENU_ITEMS.length / 2, NAV_MENU_ITEMS.length);
  all = NAV_MENU_ITEMS;

  isActive = 0;

  brTelefona = 381649341648;

  hamManuActive = false;

  makeTelNUm() {
    return 'tel:+' + this.brTelefona;
  }

  onNavItemClick(item, index) {
    this.isActive = index;
    this.router.navigate(['/'])
      .then((isNewPage) => {
          let waitTime = isNewPage ? 200 : 0;
          setTimeout(() => {
            let offset = $('#' + item.divId).offset().top - 75;
            $('html, body').animate({
              scrollTop: offset
            }, 600);
          }, waitTime)
        }
      );
    this.hamManuActive = false;
    $('body').css({overflow:''});
  }

  telImg() {
    return "assets/images/tel.png";
  }

  mainLogo() {
    return "assets/images/logo/main-logo.png";
  }

  makeHref(item) {
    return '#' + item;
  }

  toogleMenu() {
    this.hamManuActive = !this.hamManuActive;
    if (this.hamManuActive) {
      $('body').css({overflow:'hidden'});
    } else {
      $('body').css({overflow:''});
    }
  }

  constructor(private router: Router) {
  }

  resizingRPC = function() {
    // console.log('size changed');

    // if ($('#loadingimgWrapper')) {
    //   $('#loadingimgWrapper').height(300);
    //   console.log('size changed');
    // }

    var scale;
    var desktopWidth = 1220;
    var tabletWidth = 768;

    if (innerWidth < 1220) { // desktop size
      scale = 1/(desktopWidth/innerWidth);
      if (scale > 1) {scale = 1}
      // if (outerHeight < 992) {
      //   scale = 1;
      // }

      if(outerWidth < 992 && ( navigator.userAgent.match(/Android/i)
          || navigator.userAgent.match(/webOS/i)
          || navigator.userAgent.match(/iPhone/i)
          || navigator.userAgent.match(/iPad/i)
          || navigator.userAgent.match(/iPod/i)
          || navigator.userAgent.match(/BlackBerry/i)
          || navigator.userAgent.match(/Windows Phone/i)
        )){
        scale = 1
      }
    }
    else { // not desktop size
      // if (innerWidth > 767) { // tablet size
      //   scale = 1/(tabletWidth/innerWidth*1);
      // }
      scale = 1;
      // if (innerWidth < 768 && innerWidth > 622 ){
      //   scale = 1/(tabletWidth/innerWidth);
      // }
    }

    var $bwiContainerHomePage =  $('body');
    var $bwiContainerCartPage =  $('#cartPage');
    var $bwiContainerConfirmationPage =  $('#confirmationPage');
    var $bwiContainerReceiptPage =  $('#receiptPage');
    var $bwiContainerChooseNumsPage =  $('#chooseNumbersPage');

    $bwiContainerHomePage.css({
      "-webkit-transform-origin": "0 0",
      "transform-origin": "0 0",
      "-webkit-transform": "scale(" + scale + ")",
      "transform": "scale(" + scale + ")"
    });
    // $bwiContainerCartPage.css({
    //   "-webkit-transform-origin": "0 0",
    //   "transform-origin": "0 0",
    //   "-webkit-transform": "scale(" + scale + ")",
    //   "transform": "scale(" + scale + ")"
    // });
    // $bwiContainerConfirmationPage.css({
    //   "-webkit-transform-origin": "0 0",
    //   "transform-origin": "0 0",
    //   "-webkit-transform": "scale(" + scale + ")",
    //   "transform": "scale(" + scale + ")"
    // });
    // $bwiContainerReceiptPage.css({
    //   "-webkit-transform-origin": "0 0",
    //   "transform-origin": "0 0",
    //   "-webkit-transform": "scale(" + scale + ")",
    //   "transform": "scale(" + scale + ")"
    // });
    // $bwiContainerChooseNumsPage.css({
    //   "-webkit-transform-origin": "0 0",
    //   "transform-origin": "0 0",
    //   "-webkit-transform": "scale(" + scale + ")",
    //   "transform": "scale(" + scale + ")"
    // });
  }

  ngOnInit() {

    // this.resizingRPC();
    // window.addEventListener("resize", this.resizingRPC);
  }

}

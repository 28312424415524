export const SLIDER_IMAGES = [
  "assets/images/slider/s1.jpg",
  "assets/images/slider/s2.jpg",
  "assets/images/slider/s3.jpg"
];

export const CONTACT_FORM = {
  TELEFON: '+381649341648',
  MAIL: 'zavese.djina18@gmail.com',
};

export const FOOTER = {
  LOGO: {
    link:  "assets/images/logo/footer-logo.png"
  },
  PODACI: [
    {'txt':'Telefon:', 'info':'+381649341648'},
    {'txt':'Email:', 'info':'zavese.djina18@gmail.com'},
    {'txt':'Web sajt:', 'info':'www.zaveseistolnjaci.rs'},
    {'txt':'Adresa:', 'info':'Ruzveltova 13, 11000 Beograd'},
  ],
  CONTACT_ITEMS: [
    {
      imgSrc: 'assets/images/social/facebook-icon.png',
      link: 'https://www.facebook.com/zavese.draperije.stolnjaci.po.vasoj.meri/'
    },
    {
      imgSrc:"assets/images/social/instagram-icon.png",
      link: "https://www.instagram.com/zavese_la_casa_di_djina/"
    },
    {
      imgSrc: "assets/images/social/whatsapp-icon.png",
      link: "https://wa.me/381649341648"
    },
    {
      imgSrc: "assets/images/social/viber-icon.png",
      link: 'viber://chat?number=381649341648'
    },
  ]
};

export const NAV_MENU_ITEMS = [
  {
    text: 'Pocetna',
    page: 0,
    divId: 'Pocetna',
  },
  {
    text: 'Proizvodi',
    page: 0,
    divId: 'Proizvodi',
  },
  {
    text: 'Pitanja',
    page: 0,
    divId: 'Pitanja',
  },
  {
    text: 'O nama',
    page: 0,
    divId: 'about',
  },
  {
    text: 'Kontakt',
    page: 1,
    divId: 'Kontakt',
  },
  {
    text: 'Telefon',
    page: 1,
    divId: 'Kontakt',
  }
];

export const galeryImages = {
  stolnjacitxt: "Stolnjaci su jako bitni kako u svakodnevnoj primeni, tako i prilikom raznih slavlja. Nudimo veliki izbor\n" +
  "dezena, boja i materijala, kao što su: damast, teflonski damast ( damast koji ne propušta tečnost ),\n" +
  "kanafast, poliesterski damast i druge. . Na nasem sajtu nisu izloženi svi modeli iz naše ponude. Ponudu\n" +
  "možete zatražiti putem e-maila, pozivom na broj telefona ili nas posetiti na istaknutoj adresi.",

  zavesetxt:"Zavese predstavljaju jednu od bitnijih dekoracija enterijera. Iz tog razloga nudimo veliki izbor. Na nasem\n" +
  "sajtu nisu izloženi svi modeli iz naše ponude. Ponudu možete zatražiti putem e-maila, pozivom na broj\n" +
  "telefona ili nas posetiti na istaknutoj adresi.",

  stolnjaci: [
    'assets/images/products/stolnjaci/IMG_0824.jpg',
    'assets/images/products/stolnjaci/IMG_0918.jpg',
    'assets/images/products/stolnjaci/IMG_0922.jpg',
    'assets/images/products/stolnjaci/IMG_0923.jpg',
    'assets/images/products/stolnjaci/IMG_6860.jpg',
    'assets/images/products/stolnjaci/IMG_6863.jpg',
    'assets/images/products/stolnjaci/IMG_6868.jpg',
  ],
  zavese: [
    'assets/images/products/zavese/1.jpg',
    'assets/images/products/zavese/2.jpg',
    'assets/images/products/zavese/3.jpg',
    'assets/images/products/zavese/4.jpg',
    'assets/images/products/zavese/5.jpg',
    'assets/images/products/zavese/6.jpg',
    'assets/images/products/zavese/7.jpg',
    'assets/images/products/zavese/8.jpg',
    'assets/images/products/zavese/9.jpg',
    'assets/images/products/zavese/10.jpg',
    'assets/images/products/zavese/11.jpg',
    'assets/images/products/zavese/12.jpg',
    'assets/images/products/zavese/13.jpg',
    'assets/images/products/zavese/14.jpg',
    'assets/images/products/zavese/15.jpg',
    'assets/images/products/zavese/16.jpg',
    'assets/images/products/zavese/18.jpg',
    'assets/images/products/zavese/19.jpg',
    'assets/images/products/zavese/20.jpg',
    'assets/images/products/zavese/21.jpg',
    'assets/images/products/zavese/22.jpg',
    'assets/images/products/zavese/23.jpg',
    'assets/images/products/zavese/24.jpg',
    'assets/images/products/zavese/25.jpg',
    'assets/images/products/zavese/26.jpg',
    'assets/images/products/zavese/27.jpg',
    'assets/images/products/zavese/28.jpg',
    'assets/images/products/zavese/29.jpg',
    'assets/images/products/zavese/30.jpg',
  ]
};


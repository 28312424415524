import { Component, OnInit } from '@angular/core';
import { FOOTER } from "../../constants/header.js";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  podaci = FOOTER.PODACI;
  contactItems = FOOTER.CONTACT_ITEMS;

  constructor() { }

  openNetwork(link) {
    window.open(link);
  }

  footLogo () {
    return FOOTER.LOGO.link;
  }
  ngOnInit() {
  }

}
